import {
  Show,
  TabbedShowLayout,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';
import CustomImageField from "../../ui/custom-image-field";

const CustomerShow = () => (
  <Show
    title="Usuário">
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Dados de Acesso">
        <TextField source="id"/>
        <TextField source="email" label="E-mail" />
        <BooleanField source="approved" label="Aprovado" />
        <BooleanField source="active" label="Ativo" />
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Perfil">
      <CustomImageField source="image" label="Foto" showAll={true} />
        <TextField source="profile.name" label="Nome" />
        <TextField source="profile.birthdate" label="Nascimento" />
        <TextField source="profile.profession" label="Profissão" />
        <TextField source="profile.cellphone" label="Celular" />
        <TextField source="profile.company" label="Empresa" />
        <TextField source="profile.office" label="Cargo" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Endereço">
        <TextField source="address.state" label="Estado" />
        <TextField source="address.city" label="Cidade" />
        <TextField source="address.zip_code" label="CEP" />
        <TextField source="address.street" label="Rua" />
        <TextField source="address.number" label="Número" />
        <TextField source="address.complement" label="Complemento" />
        <TextField source="address.neighborhood" label="Bairro" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default CustomerShow;
