import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (evidences) =>
{
  const data = evidences.map((evidence) => ({
    id: evidence.id,
    tipo: evidence.type_evidence.name,
    usuario: evidence.customer_name,
    url: evidence.url,
    tags: evidence.tags_name,
    inicio: evidence.metadata.date_init_format,
    termino: evidence.metadata.date_end_format,
    numero_telefone_primario: evidence.metadata.primary_phone_number,
    ip: evidence.metadata.ip_address,
    modelo_aparelho: evidence.metadata.device_model,
    operadora_primaria: evidence.metadata.primary_phone_operator,
    localizacao: evidence.metadata.geolocation,
    vpn: evidence.with_vpn ? 'Sim' : 'Não',
    criado_em: evidence.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "tipo", "usuario", "url", "tags", "inicio", "termino",
             "numero_telefone_primario", "ip", "modelo_aparelho",
             "operadora_primaria", "localizacao", "vpn", "criado_em"],
  });
  downloadCSV(csv, "evidences");
};

export default exporter;
