import React from "react";
import {
  Button,
  useRecordContext
} from "react-admin";
import IconLaunch from "@mui/icons-material/Launch";
import config from "../config/config";

const ButtonEvidenceUrl = ({ source, ...props }) =>
{
  const record = useRecordContext();
  const url = config.api.url + "/evidence/" + record.token;

  const onRequest = async (e) =>
  {
    e.stopPropagation();
    const response = await fetch(url, { headers: { "Cache-Control": "no-cahe" }});
    const { meta, result } = await response.json();
    if (meta.code < 300)
      window.open(result, "_blank");
  };

  return (
    <Button label="Arquivo" onClick={onRequest}>
      <IconLaunch />
    </Button>
  );
};

export default ButtonEvidenceUrl;
