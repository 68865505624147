import React from "react";
import { Toolbar, SaveButton } from "react-admin";

const CustomSaveToolbar = () => (
  <Toolbar>
    <SaveButton label="Salvar" type="button" alwaysEnable />
  </Toolbar>
);

export default CustomSaveToolbar;
