import { SearchInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterCustomer = [
  <SearchInput source="q" alwaysOn />,
  <QuickFilter source="approved" label="Aprovados" defaultValue={true} />,
  <QuickFilter source="disapproved" label="Não Aprovados" defaultValue={true} />,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterCustomer;
