import React from "react";
import {
  Button,
  useRecordContext
} from "react-admin";
import IconLaunch from "@mui/icons-material/Launch";

const ButtonEvidenceCertificate = ({ source, ...props }) =>
{
  const record = useRecordContext();
  const onRequest = async (e) => { window.open(record.certificate.url, "_blank"); };

  return (
    <Button label="Certificado" onClick={onRequest} disabled={!(record.certificate != null)}>
      <IconLaunch />
    </Button>
  );
};

export default ButtonEvidenceCertificate;
