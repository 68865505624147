import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
import GroupButtons from "../../components/group-buttons";
import CustomFilterCustomer from "../../filter/custom-filter-customer";
import CustomListDefaultToolbar from "../../ui/custom-list-default-toolbar";
import CustomerExporter from "../../exporter/customer-exporter";
import ButtonDetails from "../../ui/button-details";
import ButtonEdit from "../../ui/button-edit";

const CustomerList = (props) => (
  <List
    title="Usuários"
    sort={{ field: "id", order: "DESC" }}
    filters={CustomFilterCustomer}
    exporter={CustomerExporter}
    actions={<CustomListDefaultToolbar />} >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="profile.name" label="Nome" sortable={false} />
      <BooleanField source="approved" label="Aprovado" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default CustomerList;
