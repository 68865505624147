import {
  Button,
  useRecordContext,
  useResourceContext,
  useRedirect,
} from "react-admin";
import IconDetails from "@mui/icons-material/Visibility";

function ButtonDetails({ label = "Detalhes" })
{
  const record = useRecordContext();
  const resource = useResourceContext();
  const redirect = useRedirect();

  const btnClick = () =>
  {
    redirect("/" + resource + "/" + record.id + "/show");
  };

  return (
    <Button onClick={btnClick} label="Detalhes">
      <IconDetails />
    </Button>
  );
}

export default ButtonDetails;
