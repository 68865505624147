import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  PasswordInput,
  BooleanInput
} from "react-admin";
import {
  hasValue,
  email,
  minLength,
  cellphone,
  birthdate
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import MaskTextInput from "../../inputs/mask-text-input";

const Schema = Yup.object(
{
  email: email(),
  password: minLength(6),
  name: hasValue(),
  profession: hasValue(),
  birthdate: birthdate(true),
  cellphone: cellphone(true)
});

const CustomerCreate = () =>
{
  return (
    <Create title="Novo Usuário" redirect="list">
      <TabbedForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />} >
        <FormTab label="Dados de Acesso">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="email" label="E-mail" type="email" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput source="password" label="Senha" type="password" fullWidth isRequired />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BooleanInput source="approved" label="Aprovado" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <BooleanInput source="active" label="Ativo" />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Perfil">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="profession" label="Profissão" fullWidth isRequired />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MaskTextInput source="birthdate" label="Nascimento" mask="00/00/0000" definitions={{ "#": /[0-9]/ }} fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MaskTextInput source="cellphone" label="Celular" mask="(00) 00000-0000" definitions={{ "#": /[0-9]/ }} fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="company" label="Empresa" fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput source="office" label="Cargo" fullWidth />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="state" label="Estado" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="city" label="Cidade" fullWidth />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MaskTextInput source="zip_code" label="CEP" mask="00000-000" definitions={{ "#": /[0-9]/ }} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="street" label="Rua" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput source="number" label="Número" fullWidth />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInput source="complement" label="Complemento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="neighborhood" label="Bairro" fullWidth />
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CustomerCreate;
