import React from "react";
import { FilterButton, CreateButton, ExportButton } from "react-admin";
import GroupButtons from "../components/group-buttons";
import CustomTopToolbar from "../custom/custom-top-toolbar";

const CustomListDefaultToolbar = ({
  hasFilter = true,
  hasCreate = true,
  hasExport = true,
}) => (
  <CustomTopToolbar>
    <GroupButtons>
      {hasFilter && <FilterButton />}
      {hasCreate && <CreateButton />}
      {hasExport && <ExportButton />}
    </GroupButtons>
  </CustomTopToolbar>
);

export default CustomListDefaultToolbar;
