import { stringify } from "query-string";
import jwt from "jwt-simple";
import axios from "axios";
import config from "../config/config";
import paramsToFormData from "../utils/params-to-form-data";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from "react-admin";

const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;

const authToken = () =>
{
  return JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)), jwtKey)).auth_token;
};

const headers = () =>
{
  const token = authToken();
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "x-api-key": apiKey,
    "x-token": "Bearer " + token
  };
};

const provider = async (type, resource, params) =>
{
  let url = "";

  const options =
  {
    headers: headers(),
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter)
      };

      if (resource === 'privacy-policy')
        url = `${apiUrl}/privacy-policy/list?${stringify(query)}`;
      else if (resource === 'terms-of-use')
        url = `${apiUrl}/terms-of-use/list?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE:
    {
      if (resource === 'evidence')
        url = `${apiUrl}/evidence/byId/${params.id}`;
      else
        url = `${apiUrl}/${resource}/${params.id}`;

      options.url = url;

      break;
    }
    case GET_MANY:
    {
      url = `${apiUrl}/${resource}`;

      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(
        {
          ...params.filter,
          [params.target]: params.id,
        })
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      options.url = url;

      break;
    }
    case UPDATE:
    {
      url = `${apiUrl}/${resource}/${params.id}`;

      options.method = "PUT";
      options.url = url;

      break;
    }
    case CREATE:
    {
      url = `${apiUrl}/${resource}`;

      options.method = "POST";
      options.url = url;

      break;
    }
    case DELETE:
    {
      url = `${apiUrl}/${resource}/${params.id}`;

      options.method = "DELETE";
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
  .then((res) =>
  {
    return res.data;
  })
  .then((response) =>
  {
    switch (type)
    {
      case GET_LIST:
      case GET_MANY:
        const data = response.data;
        const result = response.result;
        const meta = response.meta;
        var array = [];

        // REPORT PAYMENT
        if (!Array.isArray(result))
          array.push(result)

        return {
          data: data ? data : Array.isArray(result) ? result : array,
          total: meta.total || 1,
          pagination: {
            page: meta.page || 1,
            perPage: meta.perpage || 10,
          }
        };
      case GET_MANY_REFERENCE:
        return {
          data: response.result,
          total: response.result.lenght,
        };
      case GET_ONE:
        return {
          data: response.result,
        };
      case UPDATE:
      case CREATE:
        return {
          data: response.result,
        };
      default:
        return {
          data: []
        };
    }
  })
  .catch((error) =>
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
        return Promise.reject(new HttpError(error.response.data.error, error.response.status));
      else
        return Promise.reject(new HttpError(error.response.statusText, error.response.status));
    }
  });
};

export default provider;
