import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import Login from "./view/login";
import Dashboard from "./view/dashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import portugueseMessages from "./i18n/pt";
import dataProvider from "./provider/data-provider";
import authProvider from "./provider/auth-provider";
import config from "./config/config";

// ADMIN
import AdminIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminList from "./container/admin/list";
import AdminCreate from "./container/admin/create";
import AdminEdit from "./container/admin/edit";
import AdminShow from "./container/admin/show";

// PRIVACY POLICY
import PrivacyPolicyIcon from "@mui/icons-material/PrivacyTip";
import PrivacyPolicyList from "./container/privacy_policy/list";
import PrivacyPolicyCreate from "./container/privacy_policy/create";
import PrivacyPolicyShow from "./container/privacy_policy/show";

// TERMS OF USE
import TermsOfUseIcon from "@mui/icons-material/Gavel";
import TermsOfUseList from "./container/terms_of_use/list";
import TermsOfUseCreate from "./container/terms_of_use/create";
import TermsOfUseShow from "./container/terms_of_use/show";

// CUSTOMER
import CustomerIcon from "@mui/icons-material/PeopleAlt";
import CustomerList from "./container/customer/list";
import CustomerCreate from "./container/customer/create";
import CustomerEdit from "./container/customer/edit";
import CustomerShow from "./container/customer/show";

// EVIDENCE
import EvidenceIcon from "@mui/icons-material/PhonelinkLock";
import EvidenceList from "./container/evidence/list";
import EvidenceShow from "./container/evidence/show";

import theme from "./theme";
import Sidebar from "./components/sidebar";

const i18nProvider = polyglotI18nProvider(() => portugueseMessages);

const App = () => (
  <Admin
    theme={theme}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Login}
    dashboard={Dashboard}
    layout={(props) => <Layout {...props} sidebar={Sidebar} />}
    title="Jamboo - Admin">
    {(permissions) =>
    [
      // ADMIN
      <Resource
        name="admin"
        options={{ label: "Usuários Admin" }}
        icon={AdminIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminCreate : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminShow : null }
      />,

      // PRIVACY POLICY
      <Resource
        name="privacy-policy"
        options={{ label: "Políticas de Privacidade" }}
        icon={PrivacyPolicyIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyCreate : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyShow : null }
      />,

      // TERMS OF USE
      <Resource
        name="terms-of-use"
        options={{ label: "Termos de Uso" }}
        icon={TermsOfUseIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseCreate : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseShow : null }
      />,

      // CUSTOMER
      <Resource
        name="customer"
        options={{ label: "Usuários" }}
        icon={CustomerIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerCreate : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerShow : null }
      />,

      // EVIDENCE
      <Resource
        name="evidence"
        options={{ label: "Evidências" }}
        icon={EvidenceIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.evidences)) ? EvidenceList : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.evidences)) ? EvidenceShow : null }
      />
    ]}
  </Admin>
);

export default App;
