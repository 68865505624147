import {
  Create,
  SimpleForm
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomRichTextInput from '../../ui/custom-rich-text-input';
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  text: hasValue()
});

const PrivacyPolicyCreate = () =>
{
  return (
    <Create
      title="Nova Política de Privacidade"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <CustomRichTextInput source="text" label="Texto" fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default PrivacyPolicyCreate;
