import React from "react";
import { useRecordContext } from "react-admin";

const EditTitle = ({ subtitle, source }) =>
{
  const record = useRecordContext();
  if (record)
  {
    const recordSource = record[source];
    if (recordSource)
      return (
        <span>
          {subtitle} {recordSource}
        </span>
      );

    return <span>{subtitle}</span>;
  }

  return null;
};

export default EditTitle;
