import { DeleteWithConfirmButton } from "react-admin";
import { styled } from "@mui/system";

const CustomDeleteWithConfirmButton = styled(DeleteWithConfirmButton)(({ theme }) =>
  ({
    backgroundColor: "#E74646",
    color: "white",
    "&:hover":
    {
      backgroundColor: theme.palette.red["800"]
    }
  })
);

export default CustomDeleteWithConfirmButton;
