import React from 'react';
import placeholder from '../assets/placeholder.png';
import { useRecordContext } from "react-admin";

const CustomImageField = ({ source }) =>
{
  const record = useRecordContext();
  if (record && record[source])
    return (
      <img src={record[source].url} alt="Thumb" width="55" />
    );

  return (
    <img src={placeholder} alt="Placeholder" width="55" />
  );
};

export default CustomImageField;
