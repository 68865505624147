import { SearchInput, ReferenceInput, SelectInput } from "react-admin";

const CustomFilterEvidence = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo" source="type_evidence" reference="setup/types-evidence" alwaysOn >
    <SelectInput label="Tipo" optionText="name" optionValue="type" />
  </ReferenceInput>,
  <ReferenceInput label="Usuário" source="customer_id" reference="setup/customers" alwaysOn >
    <SelectInput label="Usuário" optionText="profile.name" optionValue="id" />
  </ReferenceInput>
];

export default CustomFilterEvidence;
