import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField
} from 'react-admin';
import GroupButtons from "../../components/group-buttons";
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import AdminExporter from '../../exporter/admin-exporter';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';
import ButtonDelete from "../../ui/button-delete";

const AdminList = (props) => (
  <List
    title="Usuários Admin"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ AdminExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <ArrayField label="Permissões" source="permissions" sortable={false} fieldKey="uuid">
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <GroupButtons label="Ações">
        <ButtonDetails />
        <ButtonEdit />
        <ButtonDelete />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default AdminList;
