import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  PasswordInput,
  BooleanInput
} from "react-admin";
import {
  hasValue,
  email,
  cellphone,
  birthdate
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import MaskTextInput from "../../inputs/mask-text-input";

const Schema = Yup.object(
{
  email: email(),
  profile: Yup.object().shape({
    name: hasValue(),
    profession: hasValue(),
    birthdate: birthdate(true),
    cellphone: cellphone(true)
  })
});

const CustomerEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Usuário: " source="email" />}
      redirect="list"
      mutationMode="pessimistic" >
      <TabbedForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />} >
        <FormTab label="Dados de Acesso">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <TextInput source="id" label="Id" fullWidth disabled />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput source="email" label="E-mail" type="email" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput source="password" label="Senha" type="password" fullWidth isRequired />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BooleanInput source="approved" label="Aprovado" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <BooleanInput source="active" label="Ativo" />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Perfil">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="profile.name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="profile.profession" label="Profissão" fullWidth isRequired />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MaskTextInput source="profile.birthdate" label="Nascimento" mask="00/00/0000" definitions={{ "#": /[0-9]/ }} fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MaskTextInput source="profile.cellphone" label="Celular" mask="(00) 00000-0000" definitions={{ "#": /[0-9]/ }} fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="profile.company" label="Empresa" fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput source="profile.office" label="Cargo" fullWidth />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="address.state" label="Estado" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="address.city" label="Cidade" fullWidth />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MaskTextInput source="address.zip_code" label="CEP" mask="00000-000" definitions={{ "#": /[0-9]/ }} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="address.street" label="Rua" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput source="address.number" label="Número" fullWidth />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInput source="address.complement" label="Complemento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="address.neighborhood" label="Bairro" fullWidth />
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CustomerEdit;
