import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import {
  hasValue,
  email
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  email: email(),
  password: hasValue()
});

const AdminCreate = () =>
{
  return (
    <Create
      title="Novo Usuário Admin"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="name" label="Nome" fullWidth />
        <TextInput source="email" label="E-mail" type="email" fullWidth  />
        <PasswordInput source="password" label="Senha" fullWidth  />
        <ReferenceArrayInput source="permissions" sort={{ field: 'name', order: 'ASC' }} reference="setup/permissions" >
          <SelectArrayInput label="Permissão(ões)" optionText="name" optionValue="id" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default AdminCreate;
