import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput
} from "react-admin";
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue()
});

const AdminEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Usuário Admin: " source="name" />}
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="Id" disabled />
        <TextInput source="email" label="E-mail" type="email" fullWidth disabled />
        <TextInput source="name" label="Nome" fullWidth />
        <PasswordInput source="password" label="Senha" fullWidth />
        <ReferenceArrayInput
          source="permissions_id"
          sort={{ field: "name", order: "ASC" }}
          reference="setup/permissions">
          <SelectArrayInput
            label="Permissão(ões)"
            optionText="name"
            optionValue="id"
            fullWidth />
        </ReferenceArrayInput>
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default AdminEdit;
