import { AuthProvider } from "react-admin";
import jwt from "jwt-simple";
import config from "../config/config";

const apiUrl = config.api.url;
const apikey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageUser = config.local_storage.user;
const localStorageAuth = config.local_storage.auth;

const authProvider: AuthProvider =
{
  login: ({ email, password }) =>
  {
    const request = new Request(apiUrl + `/admin/sign`,
    {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: new Headers({
        "Content-Type": "application/json",
        "x-api-key": apikey,
      }),
    });

    return fetch(request)
      .then((response) =>
      {
        if (response.status === 400)
          return Promise.reject();

        return response.json();
      })
      .then((data) =>
      {
        localStorage.setItem(
          jwt.encode(localStorageUser, jwtKey),
          jwt.encode(JSON.stringify(data.result.user), jwtKey)
        );
        localStorage.setItem(
          jwt.encode(localStorageAuth, jwtKey),
          jwt.encode(JSON.stringify(data.result.auth), jwtKey)
        );

        return Promise.resolve();
      })
      .catch((error) =>
      {
        return Promise.reject();
      });
  },
  logout: () =>
  {
    localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
    localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));

    return Promise.resolve();
  },
  checkError: (error) =>
  {
    if ((error !== null && error !== undefined) &&
        (error.status === 401 || error.status === 403))
    {
      localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
      localStorage.removeItem(jwt.encode(localStorageAuth, jwtKey));

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () =>
  {
    var item = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (item !== null)
      return jwt.decode(item, jwtKey) ? Promise.resolve() : Promise.reject();
    else
      return Promise.reject();
  },
  getPermissions: () =>
  {
    const userkey = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (userkey !== null && userkey !== undefined)
    {
      const { active, permissions } = JSON.parse(jwt.decode(userkey, jwtKey));
      return active ? Promise.resolve(permissions) : Promise.reject();
    }
    else
      return Promise.reject();
  },
};

export default authProvider;
