import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  RichTextField
} from 'react-admin';

const TermsOfUseShow = () => (
  <Show
    title="Termo de Uso">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="admin.name" label="Criado por" />
      <BooleanField source="published" label="Publicado" />
      <RichTextField source="text" label="Texto" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default TermsOfUseShow;
