import {
  Show,
  TabbedShowLayout,
  TextField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';
import ButtonEvidenceUrl from "../../ui/button-evidence-url";
import ButtonEvidenceCertificate from "../../ui/button-evidence-certificate";

const EvidenceShow = () => (
  <Show
    title="Evidência">
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Dados">
        <TextField source="id"/>
        <TextField source="type_evidence.name" label="Tipo" />
        <TextField source="customer_name" label="Usuário" />
        <ButtonEvidenceUrl label="Arquivo" blank />
        <ButtonEvidenceCertificate label="Certificado" blank />
        <ArrayField source="tags" fieldKey="uuid">
          <SingleFieldList linkType={false}>
            <ChipField source="text" />
          </SingleFieldList>
        </ArrayField>
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Metadados">
        <TextField source="metadata.date_init_format" label="Início" />
        <TextField source="metadata.date_end_format" label="Término" />
        <TextField source="metadata.primary_phone_number" label="Número de Telefone Primário" />
        <TextField source="metadata.ip_address" label="IP" />
        <TextField source="metadata.device_model" label="Modelo do Aparelho" />
        <TextField source="metadata.primary_phone_operator" label="Operadora Primária" />
        <TextField source="metadata.geolocation" label="Localização" />
        <BooleanField source="metadata.with_vpn" label="VPN" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default EvidenceShow;
