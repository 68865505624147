import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (termsOfUseList) =>
{
  const data = termsOfUseList.map((termsOfUse) => ({
    id: termsOfUse.id,
    criado_por: termsOfUse.admin.name,
    texto: termsOfUse.text_strip,
    publicado: (termsOfUse.published ? "Sim" : "Não"),
    criado_em: termsOfUse.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "criado_por", "texto", "publicado", "criado_em"],
  });
  downloadCSV(csv, "termsOfUse");
};

export default exporter;
