import {
  List,
  Datagrid,
  TextField,
  DateField
} from "react-admin";
import GroupButtons from "../../components/group-buttons";
import CustomFilterEvidence from "../../filter/custom-filter-evidence";
import CustomListDefaultToolbar from "../../ui/custom-list-default-toolbar";
import EvidenceExporter from "../../exporter/evidence-exporter";
import ButtonEvidenceUrl from "../../ui/button-evidence-url";
import ButtonEvidenceCertificate from "../../ui/button-evidence-certificate";
import ButtonDetails from "../../ui/button-details";

const EvidenceList = (props) => (
  <List
    title="Evidências"
    sort={{ field: "id", order: "DESC" }}
    filters={CustomFilterEvidence}
    exporter={EvidenceExporter}
    actions={<CustomListDefaultToolbar hasFilter={false} hasCreate={false} />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="type_evidence.name" label="Tipo" sortable={false} />
      <TextField source="customer_name" label="Usuário" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <GroupButtons label="Ações">
        <ButtonEvidenceUrl sortable={false} blank />
        <ButtonEvidenceCertificate sortable={false} blank />
        <ButtonDetails label="Detalhes" />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default EvidenceList;
