import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (customers) =>
{
  const data = customers.map((customer) => ({
    id: customer.id,
    email: customer.email,
    nome: customer.profile.name,
    nascimento: customer.profile.birthdate,
    profissao: customer.profile.profession,
    celular: customer.profile.cellphone,
    empresa: customer.profile.company,
    cargo: customer.profile.office,
    estado: customer.address.state,
    cidade: customer.address.city,
    cep: customer.address.zip_code,
    rua: customer.address.street,
    numero: customer.address.number,
    complemento: customer.address.complement,
    bairro: customer.address.neighborhood,
    aprovado: customer.approved ? 'Sim' : 'Não',
    ativo: customer.active ? 'Sim' : 'Não',
    criado_em: customer.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "email", "nome", "nascimento", "profissao", "celular", "empresa",
             "cargo", "estado", "cidade", "cep", "rua", "numero", "complemento",
             "bairro", "aprovado", "ativo", "criado_em"]
  });
  downloadCSV(csv, "customers");
};

export default exporter;
