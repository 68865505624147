import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import CustomFilterTermsOfUse from '../../filter/custom-filter-terms-of-use';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import TermsOfUseExporter from '../../exporter/terms-of-use-exporter';
import ButtonDetails from '../../ui/button-details';

const TermsOfUseList = (props) => (
  <List
    title="Termos de Uso"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterTermsOfUse }
    exporter={ TermsOfUseExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="admin.name" label="Criado por" sortable={false} />
      <BooleanField source="published" label="Publicado" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ButtonDetails label="Ações" />
    </Datagrid>
  </List>
);

export default TermsOfUseList;
