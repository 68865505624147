import { Avatar } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

function IconLogin()
{
  return (
    <Avatar sx={{ bgcolor: "primary.main" }}>
      <LockIcon />
    </Avatar>
  );
}

export default IconLogin;
