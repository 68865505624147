import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	AlignmentButtons
} from 'ra-input-rich-text';

const CustomRichTextInput = ({ size, ...props }) => (
	<RichTextInput
		toolbar={
			<RichTextInputToolbar>
				<LevelSelect size={size} />
				<FormatButtons size={size} />
        <AlignmentButtons size={size} />
			</RichTextInputToolbar>
		}
		label="Body"
		source="body"
		{...props}
	/>
);

export default CustomRichTextInput;
