import * as React from "react";
import { useLocale, useSidebarState } from "react-admin";
import { Box } from "@mui/material";

const Sidebar = ({ children }) =>
{
  const [open] = useSidebarState();
  useLocale();

  return <Box sx={{ width: open ? "auto" : 50 }}>{children}</Box>;
};

export default Sidebar;
