var env = process.env.REACT_APP_NODE_ENV || "development";

var config =
{
  development:
  {
    api:
    {
      api_key: "9769fefe4d69c815993e687397c4fe53",
      url: "https://api-dev.jamboo.legal"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard"
    },
    jwt:
    {
      key: "27ccdef55fa8b643d519116844583e7a"
    },
    permissions:
    {
      users_admin: "fbb883006ce51fbbabf0388112f80162",
      privacy_policy: "5cecd5668bb83177e5aad8a2e573711d",
      terms_of_use: "6cc1ed43311d16153663ca3e00b90a70",
      customers: "456722a870e20541ba71211a098030da",
      evidences: "b6e7c3ae7ff32b4c52fdc68bace82f47"
    }
  },
  production:
  {
    api:
    {
      api_key: "9769fefe4d69c815993e687397c4fe53",
      url: "https://api.jamboo.legal"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard"
    },
    jwt:
    {
      key: "42fd3b2a67745b76dfdc78ee4c1d9a36"
    },
    permissions:
    {
      users_admin: "fbb883006ce51fbbabf0388112f80162",
      privacy_policy: "5cecd5668bb83177e5aad8a2e573711d",
      terms_of_use: "6cc1ed43311d16153663ca3e00b90a70",
      customers: "456722a870e20541ba71211a098030da",
      evidences: "b6e7c3ae7ff32b4c52fdc68bace82f47"
    }
  }
};

module.exports = config[env];
