import {
  SearchInput
} from 'react-admin';
import QuickFilter from "../ui/quick-filter";

const CustomFilterPrivacyPolicy = [
  <SearchInput source="q" alwaysOn />,
  <QuickFilter source="published" label="Publicados" defaultValue={true} />,
  <QuickFilter source="unpublished" label="Não Publicados" defaultValue={true} />
];

export default CustomFilterPrivacyPolicy;
